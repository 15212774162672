import { useEffect } from "react";
import { createPortal } from "react-dom";
import "./../modal.css";

const Portal = ({ children }) => {
  const mount = document.getElementsByTagName("body")[0];
  const el = document.createElement("div");

  useEffect(() => {
    mount.appendChild(el);
    return function cleanup() { mount.removeChild(el); }
  }, [el, mount]);

  return createPortal(children, el);
};

export default Portal;
