import { Language } from "@canadahelps/localization";
import { englishText } from "./messages_en";
import { frenchText } from "./messages_fr";

export type TLocalization = {
  lang: Language;
  text: TAppText;
};

export type TAppText = {
  someMessage(): string;
  donateButton(): string;
  close(): string;
};

export type TMessages = {
  en: TAppText;
  fr: TAppText;
};

export const messages: TMessages = {
  en: englishText,
  fr: frenchText,
};

export const getMessages = (lang: string) => {
  return {
    text: {
      ...(lang.toLocaleLowerCase() === "en" ? messages.en : messages.fr),
    },
  };
};
