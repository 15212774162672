/* eslint-disable complexity */
import React, { useEffect, useState, ReactElement } from "react";
import "./modal.css";
import IframeResizer from "iframe-resizer-react";
import Portal from "./portal/portal";
import { getMessages } from "../locale/local";
import { BeatingHeart } from "../static/beating-heart";

const widgetApiEndpoint = `${process.env.WIDGET_API_ENDPOINT}/site/api/widgetDetails`;

export enum StickyButtonState {
  NONE = "NONE",
  BOTTOM_CENTRE = "BOTTOM_CENTRE",
  BOTTOM_LEFT = "BOTTOM_LEFT",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
}
export type TBranding = {
  style: string;
  hasIcon: boolean;
  stickyButton: StickyButtonState;
};
export type TWidgetApiResponse = {
  widgetID?: string;
  charityID: number;
  formID: number;
  widgetType: number;
  branding: TBranding;
};
type TProps = {
  lang: string;
  widgetId: string;
  anchor: HTMLElement;
  v1?: string;
  v2?: string;
};

function messageCallbackHandler(messageData) {
  const message = messageData.message;
  switch (message.messageType) {
    case "REDIRECT":
      if (message.payload) {
        window.location.href = message.payload;
      }
      break;
    default:
      break;
  }
}

export const getNavigator = () => {
  if (typeof window !== "undefined") {
    return window.navigator;
  }
};

export const getUserAgent = () => {
  return getNavigator().userAgent;
};

export const isMobile = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const MobileDetect = require("mobile-detect");
  const md = new MobileDetect(getUserAgent());
  return md.mobile() != null;
};

export default function Button(props: TProps): ReactElement {
  const [model, setModel] = useState(undefined);
  const [shouldRenderModal, setShouldRenderModal] = useState(false);
  const { text } = getMessages(props.lang);
  const showModel = () => {
    const modal = document.getElementById("CH-modal");
      modal.style.display = "flex";
      const body = document.getElementsByTagName("body")[0];
      body.style.overflow = "hidden";
  }
  
  React.useEffect(() => {
    if (shouldRenderModal){
      showModel();
    }
  }, [shouldRenderModal]);

  const open = () => {
    if (shouldRenderModal){
      showModel()
    }else{
      setShouldRenderModal(true);
    }
  };
  const close = () => {
    const modal = document.getElementById("CH-modal");
    modal.style.display = "none";
    const body = document.getElementsByTagName("body")[0];
    body.style.overflow = "auto";
  };

  const escFunction = (event) => {
    if (event.key === "Escape") {
      close();
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", escFunction);
    return () => {
      window.removeEventListener("keydown", escFunction);
    };
  });

  useEffect(() => {
    const endpoint = `${widgetApiEndpoint}/${props.widgetId}`;
    fetch(endpoint)
      .then((res) => res.json())
      .then(
        (result: TWidgetApiResponse) => {
          result.branding = JSON.parse(result.branding);
          setModel(result);
        },
        (error) => {
          console.error(error);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let iframeURL =  `${process.env.DN_ENDPOINT}/${props.lang ? props.lang : "en"}/dn/w/${model?.formID}?widget=true`;
  if (!model) {
    return <span dangerouslySetInnerHTML={{ __html: props.anchor.outerHTML }} />;
  }

  const stickButtonStyleCalculator = {
    [StickyButtonState.BOTTOM_CENTRE]: { bottom: 0, left: "50%", transform: "translateX(-50%)" },
    [StickyButtonState.BOTTOM_LEFT]: { bottom: 0, left: 0 },
    [StickyButtonState.BOTTOM_RIGHT]: { bottom: 0, right: 0 },
  };

  const buttonStyle = {
    ...model?.branding?.style,
    padding: "8px 16px",
  };

  const linkStyle = { color: model?.branding?.style?.backgroundColor || "#1C75BC" };

  const isPopUpEnabled = model?.branding?.popUp?.enabled || false;
  const imgURL =
    model?.branding?.popUp?.image && model?.branding?.popUp?.imageExtension
      ? `${process.env.ENVIRONMENT}${model?.branding?.popUp?.image}.${model?.branding?.popUp?.imageExtension}`
      : null;

  const getImpactTitle = () => {
    if (props.lang === "en") {
      return model?.branding?.popUp?.impactHeadingEN
        ? model?.branding?.popUp?.impactHeadingEN
        : model?.branding?.popUp?.impactHeadingFR;
    } else {
      return model?.branding?.popUp?.impactHeadingFR
        ? model?.branding?.popUp?.impactHeadingFR
        : model?.branding?.popUp?.impactHeadingEN;
    }
  };
  const title = getImpactTitle();

  const getImpactDescription = () => {
    if (props.lang === "en") {
      return model?.branding?.popUp?.impactMessageEN
        ? model?.branding?.popUp?.impactMessageEN
        : model?.branding?.popUp?.impactMessageFR;
    } else {
      return model?.branding?.popUp?.impactMessageFR
        ? model?.branding?.popUp?.impactMessageFR
        : model?.branding?.popUp?.impactMessageEN;
    }
  };
  const description = getImpactDescription();

  const getImpactLinkText = () => {
    if (props.lang === "en") {
      return model?.branding?.popUp?.impactLinkTextEN
        ? model?.branding?.popUp?.impactLinkTextEN
        : model?.branding?.popUp?.impactLinkTextFR;
    } else {
      return model?.branding?.popUp?.impactLinkTextFR
        ? model?.branding?.popUp?.impactLinkTextFR
        : model?.branding?.popUp?.impactLinkTextEN;
    }
  };
  const linkText = getImpactLinkText();

  const getImpactLinkURL = () => {
    if (props.lang === "en") {
      return model?.branding?.popUp?.impactLinkURLEN
        ? model?.branding?.popUp?.impactLinkURLEN
        : model?.branding?.popUp?.impactLinkURLFR;
    } else {
      return model?.branding?.popUp?.impactLinkURLFR
        ? model?.branding?.popUp?.impactLinkURLFR
        : model?.branding?.popUp?.impactLinkURLEN;
    }
  };
  const linkURL = getImpactLinkURL();

  return (
    <>
      <button id="ch-give" style={buttonStyle} onClick={open} tabIndex={0} aria-label={text.donateButton()}>
        {model?.branding?.hasIcon && <BeatingHeart />}
        {text.donateButton()}
      </button>
      {model?.branding.stickyButton && model?.branding.stickyButton !== StickyButtonState.NONE && (
        <button
          id="ch-give-sticky"
          style={{
            ...buttonStyle,
            ...stickButtonStyleCalculator[model?.branding.stickyButton],
            position: "fixed",
            zIndex: 1000000,
          }}
          onClick={open}
          tabIndex={0}
          aria-label={text.donateButton()}
        >
          {model?.branding?.hasIcon && <BeatingHeart />}
          {text.donateButton()}
        </button>
      )}
      { shouldRenderModal &&
      <Portal>
        <div id="CH-modal" className="ch-widget-modal" style={isMobile() ? { paddingBottom: "115px" } : undefined}  tabIndex={0}>
          <div className="ch-widget-modal-overlay" tabIndex={0}></div>
          {/** TODO: The span below should be a <button> if it has an onClick */}
          <span className="close-button" onClick={close}>
            &times;
          </span>
          <div className="iframe-container">
            {isPopUpEnabled && (
              <div className="popup-container">
                {imgURL && (
                  <div
                    style={{
                      backgroundImage: `url(${imgURL})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      height: "282px",
                      width: "100%",
                      maxWidth: "450px",
                    }}
                  />
                )}
                <div className="popup-text">
                  {title && title.length > 0 && <h1>{title}</h1>}
                  {description && description.length > 0 && <p>{description}</p>}
                </div>
                {linkText && linkText.length > 0 && linkURL && linkURL.length > 0 && (
                  <div className="link-container link-container--desktop">
                    <a style={linkStyle} href={linkURL}>
                      {linkText}
                    </a>
                  </div>
                )}
              </div>
            )}
            <IframeResizer
              onMessage={messageCallbackHandler}
              aria-label="dialog-content"
              src={iframeURL}
              className="ch-widget-modal-content"
              scrolling={true}
              allow="payment"
            />
            {isPopUpEnabled && linkText && linkText.length > 0 && linkURL && linkURL.length > 0 && (
              <div className="link-container link-container--mobile">
                <a style={linkStyle} href={linkURL}>
                  {linkText}
                </a>
              </div>
            )}
          </div>
        </div>
      </Portal>
      }
    </>
  );
}
