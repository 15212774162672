import Button from "./components/Button";
import * as React from "react";
import * as ReactDOM from "react-dom";

const resolveAnchorTags = (): void => {
  const anchors = document.querySelectorAll(`a[href="#BDA964"]`);
  anchors.forEach((anchor: HTMLElement) => {
    const widgetId = anchor.getAttribute("data-widget-id");
    const v1 = anchor.getAttribute("data-v1");
    const v2 = anchor.getAttribute("data-v2");
    const lang = anchor.getAttribute("data-lang");
    const temp = document.createElement("span");
    ReactDOM.render(<Button lang={lang} widgetId={widgetId} anchor={anchor} v1={v1} v2={v2} />, temp);
    anchor.parentElement.replaceChild(temp, anchor);
    // renderButton(anchor, widgetId, lang);
  });
};

const init = () => {
  resolveAnchorTags();
};

init();
